import { Typography, Container, Paper } from "@material-ui/core";
import React, { useEffect } from 'react';


export default function Terms() {

    useEffect(() => {


    }, []);


    return (

        <Container maxWidth="md" style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",

            marginTop: 10
        }}>

            <Paper elevation={0} style={{ padding: 10 }}>
                <div>
                    <h5>Fantasy Jutsu</h5>
                    <Typography variant="caption">
                        Fantasy Jutsu is a online fantasy game platform which offers different flavours of contest for cricket matches.
                </Typography>
                </div>

                <div>
                    <h5>Usage</h5>
                    <Typography variant="caption">
                        1. Any person using Fantasy Jutsu platform for participating in the various contests and games shall be bound by these Terms and Conditions.
                                </Typography>
                    <br />
                    <br />
                    <Typography variant="caption">
                        2.  In the event any User breaches, or we believe that a User has breached these Terms and Conditions, or has illegally or improperly used our Services, we may, at its sole and absolute discretion, and without any notice to the User, restrict, suspend or terminate such User's access to our platform.
                                </Typography>
                </div>

                <div>
                    <h5>MEMBER REGISTRATION</h5>
                    <Typography variant="caption">
                        If You wish to become a registered member of Our websites and mobile applications, You will need to apply for a member account (Account). Members are only permitted to open one account. Fantasyjutsu reserves the right to close any duplicate accounts. All money held in duplicate account(s) will be forfeited. In order to apply for an Account, You must complete all details in accordance with the instructions on the Website.
                 </Typography>
                    <br />
                    <Typography variant="caption">
                        Upon grant of the Account by Us, your Account will be created and You will be able to use Our websites and mobile applications and play the contest(s) with Us. We may refuse to grant an application for an Account made by any person without giving a reason for such refusal.
                                </Typography>
                    <br />
                    <Typography variant="caption">
                        Upon grant of the Account by Us, your Account will be created and You will be able to use Our websites and mobile applications and play the contest(s) with Us. We may refuse to grant an application for an Account made by any person without giving a reason for such refusal.
                        You continuously represent and warrant to Us that persons accessing Your Account are authorised to do so. You are not authorised to enter contests on Our websites and mobile applications or otherwise until you have been granted an Account.
                                </Typography>
                    <br />
                    <Typography variant="caption">
                        You must be over 18 years of age and a resident of India to play on Our websites and mobile applications. Fantasyjutsu may, in accordance with the laws prevailing in certain Indian states, bar individuals residing in those states from participating in the Contest(s). Currently, individuals residing in the Indian states of Assam, Odisha and Telangana may not participate in the paid version of the Contest as the laws of these states bar persons from participating in games of skill where participants are required to pay to enter.
                                  </Typography>
                    <br />
                    <Typography variant="caption">
                        You will also be required to verify any credit card / bank account you use to deposit funds, and also any bank account you want to withdraw to.
                                  </Typography>
                    <br />
                    <Typography variant="caption">
                        By accepting these terms and conditions upon registration You give consent for fantasyjutsu.com to disclose your name, residential address and date of birth to a third party agency to assist in verifying your identity. The third party agency may prepare and provide fantasyjutsu.com with such an assessment and may use your personal information including the names, residential addresses and dates of birth for the purposes of preparing such an assessment.
                                </Typography>

                </div>
                <div>
                    <h5>Intellectual Property</h5>
                    <Typography variant="caption">
                        Unless indicated otherwise, all intellectual property in our websites and mobile applications is owned or licensed by Us.
                        You agree that You will not infringe our intellectual property as contained in our websites and mobile applications and will not modify, copy, republish, frame, distribute or communicate any part of our websites and mobile applications or any information contained in it or otherwise use our websites and mobile applications in a way which will infringe our intellectual property or other rights.
                 </Typography>

                </div>
                <div>
                 
      <h5 >User Conduct</h5>
      <div  >
        <ul>
          <li > <Typography variant="caption">Users agree to abide by these Terms and Conditions and all other rules, regulations and terms of use of the Website. In the event User does not abide by these Terms and Conditions and all other rules, regulations and terms of use, Fantasy Jutsu may, at its sole and absolute discretion, take necessary remedial action, including but not limite</Typography>d to:
            <ul>
              <li > <Typography variant="caption">restricting, suspending, or terminating any User's access to all or any part of Fantasy Jutsu Services;</Typography></li>
              <li > <Typography variant="caption">deactivating or deleting a User's account and all related information and files on the account. Any amount remaining unused in the User's Game account or Winnings Account on the date of deactivation or deletion shall be transferred to the User's bank account on record with Fantasy Jutsu subject to a processing fee (if any) applicable on such transfers as set out herein; or</Typography></li>
              <li > <Typography variant="caption">refraining from awarding any prize(s) to such User.</Typography></li>
            </ul>
          </li>
          <li > <Typography variant="caption">Users agree to provide true, accurate, current and complete information at the time of registration and at all other times (as required by Fantasy Jutsu). Users further agree to update and keep updated their registration information</Typography></li>
          <li > <Typography variant="caption">A User shall not register or operate more than one User account with Fantasy Jutsu.</Typography></li>
          <li > <Typography variant="caption">Users agree to ensure that they can receive all communication from Fantasy Jutsu by marking e-mails or sending SMSs from Fantasy Jutsu as part of their "safe senders" list. Fantasy Jutsu shall not be held liable if any e-mail/SMS remains unread by a User as a result of such e-mail getting delivered to the User's junk or spam folder.</Typography></li>
          <li > <Typography variant="caption">Any password issued by Fantasy Jutsu to a User may not be revealed to anyone else. Users may not use anyone else's password. Users are responsible for maintaining the confidentiality of their accounts and passwords. Users agree to immediately notify Fantasy Jutsu of any unauthorized use of their passwords or accounts or any other breach of security.</Typography></li>
          <li > <Typography variant="caption">Users agree to exit/log-out of their accounts at the end of each session. Fantasy Jutsu shall not be responsible for any loss or damage that may result if the User fails to comply with these requirements.</Typography></li>
          <li > <Typography variant="caption">Users agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorised third party software designed to modify or interfere with Fantasy Jutsu Services and/or Fantasy Jutsu experience or assist in such activity.</Typography></li>
          <li > <Typography variant="caption">Users agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying Fantasy Jutsu or Fantasy Jutsu’s Services.</Typography></li>
          <li > <Typography variant="caption">Users agree that without Fantasy Jutsu's express written consent, they shall not modify or cause to be modified any files or software that are part of Fantasy Jutsu's Services.</Typography></li>
          <li > <Typography variant="caption">Users agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support Fantasy Jutsu or the Fantasy Jutsu’s Services (each a "Server"); or (2) the enjoyment of Fantasy Jutsu Services by any other User or person.</Typography></li>
          <li > <Typography variant="caption">Users agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt Fantasy Jutsu Services or any other person's use or enjoyment of Fantasy Jutsu Services.</Typography></li>
          <li > <Typography variant="caption">Users shall not attempt to gain unauthorised access to the User accounts, Servers or networks connected to Fantasy Jutsu Services by any means other than the User interface provided by Fantasy Jutsu, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of Fantasy Jutsu Services.</Typography></li>
          <li > <Typography variant="caption">Without limiting the foregoing, Users agree not to use Fantasy Jutsu for any of the follo</Typography>wing:
            <ul>
              <li > <Typography variant="caption">To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication;</Typography></li>
              <li > <Typography variant="caption">To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;</Typography></li>
              <li > <Typography variant="caption">To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;</Typography></li>
              <li > <Typography variant="caption">To Transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, Fantasy Jutsu, any software, hardware, or telecommunications equipment;</Typography></li>
              <li > <Typography variant="caption">To advertise, offer or sell any goods or services for any commercial purpose on Fantasy Jutsu without the express written consent of Fantasy Jutsu;</Typography></li>
               <li > <Typography variant="caption">To download any file, recompile or disassemble or otherwise affect our products that you know or reasonably should know cannot be legally obtained in such manner;</Typography></li>
              <li > <Typography variant="caption">To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;</Typography></li>
              <li > <Typography variant="caption">To restrict or inhibit any other user from using and enjoying any public area within our sites;</Typography></li>
              <li > <Typography variant="caption">To collect or store personal information about other Users;</Typography></li>
              <li > <Typography variant="caption">To interfere with or disrupt Fantasy Jutsu, servers, or networks;</Typography></li>
              <li > <Typography variant="caption">To impersonate any person or entity, including, but not limited to, a representative of Fantasy Jutsu, or falsely state or otherwise misrepresent User's affiliation with a person or entity;</Typography></li>
              <li > <Typography variant="caption">To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through Fantasy Jutsu or to manipulate User's presence on Fantasy Jutsu(s);</Typography></li>
              <li > <Typography variant="caption">To take any action that imposes an unreasonably or disproportionately large load on our infrastructure;</Typography></li>
              <li > <Typography variant="caption">To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to that particular Forum.</Typography></li>
            </ul>
          </li>
          <li > <Typography variant="caption">If a User chooses a username that, in Fantasy Jutsu's considered opinion is obscene, indecent, abusive or that might subject Fantasy Jutsu to public disparagement or scorn, or a name which is an official team/league/franchise names and/or name of any sporting personality, as the case may be, Fantasy Jutsu reserves the right, without prior notice to the User, to restrict usage of such names, which in Fantasy Jutsu’s opinion fall within any of the said categories and/or change such username and intimate the User or delete such username and posts from Fantasy Jutsu, deny such User access to Fantasy Jutsu, or any combination of these options.</Typography></li>
          <li > <Typography variant="caption">Unauthorized access to Fantasy Jutsu is a breach of these Terms and Conditions, and a violation of the law. Users agree not to access Fantasy Jutsu by any means other than through the interface that is provided by Fantasy Jutsu for use in accessing Fantasy Jutsu. Users agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing.</Typography></li>
          <li > <Typography variant="caption">Use of Fantasy Jutsu is subject to existing laws and legal processes. Nothing contained in these Terms and Conditions shall limit Fantasy Jutsu's right to comply with governmental, court, and law-enforcement requests or requirements relating to Users' use of Fantasy Jutsu.</Typography></li>
 
          <li > <Typography variant="caption">Persons below the age of eighteen (18) years are not allowed to participate on any of the contests, games (by whatever name called) on the Fantasy Jutsu Platform. The Users will have to disclose their real age at the time of getting access into the Fantasy Jutsu Platform.</Typography></li>
          <li > <Typography variant="caption">Fantasy Jutsu may not be held responsible for any content contributed by Users on the Fantasy Jutsu.</Typography></li>
        </ul>
      </div>
   
                </div>
                <div>
                    <h5>Refund Policy</h5>
                    <Typography variant="caption">
                    A transaction, once confirmed, is final and no cancellation is permissible. However, FantasyJutsu may, at its sole and absolute discretion, permit a Member to cancel a transaction and refund the amount paid: If the Member sends a written request to FantasyJutsu from the registered email Id to cancel such payment; or If the payment is made for participation in the paid version(s) of the Contest(s), the cancellation request must be received at least 2 days prior to the commencement of the round in respect of which the payment is made; FantasyJutsu shall not be liable to refund any amount thereafter. FantasyJutsu may, at its sole and absolute discretion, refund the amount to the Member after deducting applicable cancellation charges and taxes. However, if in a transaction performed by you on the FantasyJutsu Platform, money has been charged to your card or bank account and respective amount is not added in your FantasyJutsu account within 24 hours of the completion of the transaction, then you shall inform us by sending an e-mail to support@FantasyJutsuapp.com from your registered e-mail address. Please include in the e-mail the following details – the mobile number, transaction value, transaction date and transaction id. FantasyJutsu will investigate the incident and, if it is found that money was indeed charged to your card or bank account without delivery of the balance in your FantasyJutsu account, then you will be refunded the money within 7 working days from the date of receipt of your e mail. All refunds will be credited to your FantasyJutsu account. 
                    </Typography>
                </div>


                <div>
                    <h5>Third Party Services</h5>
                    <Typography variant="caption">
                        Our websites and mobile applications contain content provided to Us by other parties (Third Party Content).
                        This includes the information relating to sport, fantasy sport and player stats.
                        We are not responsible for,
                        do not endorse and make no representations either expressly or impliedly concerning Third Party Content.
                        Third Party Content does not represent Our views.
                        You rely on Third Party Content completely at Your own risk.
                        We do not guarantee the accuracy, integrity, quality of the content provided by third parties and
                        such content may not relied upon by the
                        users in utilizing the services provided on our platform
                        including while participating in any of the contests hosted.
                </Typography>
                    <br />

                </div>


                <div>
                    <h5>Winners and Taxes</h5>
                    <Typography variant="caption">
                        Winners will be decided on the basis of the scores of the
                        Teams in a designated round of the Contest(s).
                        The Participant(s) owning the Team(s)
                        with the highest aggregate score in a
                        particular round shall be declared the Winner(s).
                        In certain specified Contests, we may declare more
                        than one Winner and distribute prizes to such Winners in
                        increasing order of their Team's aggregate score at the end of the designated round
                        of the Contest.
                        The contemplated number of Winners and the prize due to
                        each Winner in such Contest shall be as specified on the
                        Contest page prior to the commencement of the Contest.
                        Participants creating Teams on behalf of any other Participant or
                        person shall be disqualified. In the event of a tie, the winning
                        Participants shall be declared Winners and the prize shall be equally
                        divided among such Participants. We shall not be liable to
                        pay any prize if it is discovered that the Winner(s) have
                        not abided by these Terms and Conditions, and other rules and
                        regulations in relation to the use of the fantasyjutsu.com, Contest, “Fantasy Rules”, etc.
                </Typography>
                    <br />
                    <br />
                    <Typography variant="caption">
                        Winners can only withdraw their winnings when they verify their ID proof, pan card and address proof.
                        In the cases where a user is found to have submitted a fake copy of the documents, we will terminate his/her account along with the winnings.
                              </Typography>

                </div>

                <h5>Taxes Payable</h5>
                <Typography variant="caption">
                    All prizes shall be subject to deduction of tax ("TDS") as per the Income Tax Act 1961. As of April 1, 2018, the TDS rate prescribed by the Government of India with respect to any prize money amount that is in excess of Rs. 10,000/- is 30% of the total prize money amount. In case of any revisions by the Government of India to the aforementioned rate in the future, TDS will be deducted in accordance with the then current prescribed TDS rate. Winners will be provided TDS certificates in respect of such tax deductions. The Winners shall be responsible for payment of any other applicable tax, including but not limited to, income tax, gift tax, etc. in respect of the prize money.
                </Typography>
                <h5>A Game of Skill</h5>
                <Typography variant="caption">
                    The contests hosted on the site is strictly based on a user knowledge of players. The different flavours of contest
                    that are hosted here are analysed carefully to totally omit the factor of luck.
                    A user winning a certain contest strictly depends on a player real life historical stats and not on the outcome of luck or chance.
            <br />
            By participating in this Contest(s), each Participant acknowledges and agrees that he/she is participating in a game of skill.
            </Typography>
            </Paper>
        </Container>

    )

}